//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import contentRendererMixin from 'kolibri.coreVue.mixins.contentRendererMixin';
import { now } from 'kolibri.utils.serverClock';
import UiIconButton from 'kolibri.coreVue.components.UiIconButton';
import CoreFullscreen from 'kolibri.coreVue.components.CoreFullscreen';
import Hashi from 'hashi';
import { nameSpace } from 'hashi/src/hashiBase';

export default {
  name: 'Html5AppRendererIndex',
  components: {
    UiIconButton,
    CoreFullscreen,
  },
  mixins: [contentRendererMixin, themeMixin],
  props: {
    defaultFile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInFullscreen: false,
    };
  },
  computed: {
    name() {
      return nameSpace;
    },
    rooturl() {
      return this.defaultFile.storage_url;
    },
  },
  mounted() {
    this.hashi = new Hashi({ iframe: this.$refs.iframe, now });
    this.hashi.onStateUpdate(data => {
      this.$emit('updateContentState', data);
    });
    this.hashi.initialize((this.extraFields && this.extraFields.contentState) || {});
    this.$emit('startTracking');
    this.startTime = now();
    this.pollProgress();
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.$emit('stopTracking');
  },
  methods: {
    recordProgress() {
      const totalTime = now() - this.startTime;
      this.$emit('updateProgress', Math.max(0, totalTime / 300000));
      this.pollProgress();
    },
    pollProgress() {
      this.timeout = setTimeout(() => {
        this.recordProgress();
      }, 15000);
    },
  },
  $trs: {
    exitFullscreen: 'Exit fullscreen',
    enterFullscreen: 'Enter fullscreen',
  },
};

